<template>
  <!-- 线索公海模块 -->
  <div class="openSea bgblue pad10">
    <!-- 线索公海头部模块 -->
    <div class="openSea-top">
      <el-form
        :model="formInline"
        class="demo-form-inline"
        label-width="80px"
      >
        <el-row>
          <el-col :span="4">
            <el-form-item label="姓名:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="线索编号:">
              <el-input
               
                v-model="formInline.user"
                placeholder="请输入线索编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="证件号:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入证件号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="手机号:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="分配状态:">
              <el-select v-model="allocationStatus.region" placeholder="请选择分配状态" style="width:100%">
                <el-option
                  v-for="item in allocationStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="标签:">
              <el-select v-model="tab.region" placeholder="请选择标签" style="width:100%">
                <el-option
                  v-for="item in tab"
                  :key="item.region"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="线索来源:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入线索来源"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="客户类型:">
              <el-select v-model="userType.region" placeholder="请选择客户类型" style="width:100%">
                <el-option
                  v-for="item in userType"
                  :key="item.region"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="线索类型:">
              <el-select v-model="clueType.region" placeholder="请选择线索类型" style="width:100%">
                <el-option
                  v-for="item in clueType"
                  :key="item.region"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div v-if="advanced">
          <el-col :span="4">
            <el-form-item label="所属分公司:">
              <el-input
                v-model="formInline.user"
                placeholder="请输入所属分公司"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="区域:">
              <el-select v-model="area.region" placeholder="请选择区域" style="width:100%">
                <el-option
                  v-for="item in area"
                  :key="item.region"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> </el-form-item
          ></el-col>
          <el-col :span="9">
            <el-form-item label="创建时间:">
              <el-date-picker
              style="width: 95%;"
                v-model="value1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          </div>
          <el-col :span="4">
            <div class="pt5">
              <el-button type="text" @click="toggleAdvanced"><i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>{{ advanced ? '收起' : '展开' }}</el-button>
            <el-button type="primary"><i class="el-icon-search"></i>查询</el-button>
            <el-button plain> <i class="el-icon-refresh"></i>重置</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- 线索公海底部模块 -->
    <div class="openSea-bottom">
      <el-button type="primary" @click="centerDialogVisible = true"
        >新增线索</el-button
      >
      <el-dialog
        title="新增线索"
        :visible.sync="centerDialogVisible"
        width="40%"
      >
        <el-form
          :model="culeForm"
          :rules="increasingRules"
           label-width="60px"
          class="demo-ruleForm"
          ref="increasingForm"
        >
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名" prop="customerName"  placeholder="请输入" required >
            <el-input v-model="culeForm.customerName" clearable></el-input>
          </el-form-item>
          </el-col>
          <el-col :span="12"> <el-form-item label="电话" prop="phone"  placeholder="请输入" required>
            <el-input v-model="culeForm.phone" clearable></el-input>
          </el-form-item></el-col>
          <el-col :span="12"><el-form-item label="性别"  prop="sex" >
            <el-select
            style="width:100%"
            v-model="culeForm.sex"
            @change="changeStatus"
            placeholder="请选择"
            clearable
          >
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
            <el-option label="未知" value="3"></el-option>
          </el-select>
          </el-form-item></el-col>
          <el-col :span="12"> <el-form-item label="年龄"  prop="age">
            <el-input v-model="culeForm.age" placeholder="请输入" clearable></el-input>
          </el-form-item></el-col>
          <el-col :span="12"> <el-form-item label="微信"  prop="wxNo">
            <el-input v-model="culeForm.wxNo" placeholder="请输入" clearable></el-input>
          </el-form-item></el-col>
          <el-col :span="12"><el-form-item label="来源"  prop="clueSource">
            <el-input v-model="culeForm.user" placeholder="请输入" clearable></el-input>
          </el-form-item></el-col>
          <el-col :span="12"><el-form-item label="地址"  prop="address">
            <el-input v-model="culeForm.address" placeholder="请输入" clearable></el-input>
          </el-form-item></el-col>
          <el-col :span="24"><el-form-item label="详细地址" prop="clueAddress" >
                <el-input type="textarea" v-model="culeForm.desc" :maxlength="maxlength"  show-word-limit   @input="handleInput" clearable>
                </el-input>
              </el-form-item></el-col>
              <el-col :span="24"> <el-form-item label="备注" prop="remark">
                <el-input type="textarea" v-model="culeForm.remark" :maxlength="maxlength"  show-word-limit   @input="handleInput" clearable></el-input>
              </el-form-item></el-col>
        </el-row>
        </el-form>
        <el-row  type="flex" justify="center" class="mt20">
            <el-button @click="centerDialogVisible = false">重置</el-button>
            <el-button @click="centerDialogVisible = false">保存并继续</el-button>
          <el-button type="primary" @click="sumbitsaveClue('increasingForm')" 
            >保存</el-button>
        </el-row>
      </el-dialog>
      <el-button disabled style="margin-left: 10px;">分配</el-button>
      <el-button disabled>领取</el-button>
      <el-button plain>导入线索</el-button>
      <el-table
        :data="tableData"
        height="calc(100vh - 320px)"
        border
      >
      <el-table-column
          type="selection"
          align="center"
          width="35px"
        >
        </el-table-column>
        <el-table-column
          prop="customerName"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="status"
          label="线索状态"
          align="center"
          width="150px"
        >
        <el-tag type="success">已完成</el-tag>
        </el-table-column>
        <el-table-column
          prop="tag"
          label="线索标签"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="agentName"
          label="代理商"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="customerType"
          label="客户类型"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="clueCode"
          label="线索编号"
          align="center"
          width="250px"
        >
        </el-table-column>
        <el-table-column
          prop="allocationStatus"
          label="分配状态"
          align="center"
          width="150px"
        >
        </el-table-column>

        <el-table-column
          prop="clueType"
          label="线索类型"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="clueSource"
          label="线索来源"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="headName"
          label="负责人"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="clueCity"
          label="所在城市"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="clueAddress"
          label="地址"
          align="center"
          width="150px"
        ></el-table-column>
        <el-table-column
          prop="returnCount"
          label="回访次数"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="followRecord"
          label="跟进记录"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column prop="eMail" label="邮箱" align="center" width="150px">
        </el-table-column>
        <el-table-column
          prop="certificateType"
          label="
          证件类型"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="certificateNo"
          label="证件号"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="birthday"
          label="生日"
          align="center"
          width="150px"
        ></el-table-column>
        <el-table-column prop="age" label="年龄" align="center" width="150px">
        </el-table-column>
        <el-table-column prop="wxNo " label="微信" align="center" width="150px">
        </el-table-column>
        <el-table-column prop="wbNo" label="微博" align="center" width="150px">
        </el-table-column>
        <el-table-column
          prop="headManagerName"
          label="总部负责人"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="headManagerDate"
          label="总部负责时间"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="companyManagerName"
          label="分公司负责人"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="companyManagerDate"
          label="分公司负责时间(块)"
          align="center"
          width="150px"
        >
        </el-table-column>

        <el-table-column
          prop="company_name"
          label="所属分公司"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="updateName"
          label="更新人"
          align="center"
          width="150px"
        >
        </el-table-column>
        <el-table-column
          prop="updateDate"
          label="更新时间"
          align="center"
          width="150px"
        >
        </el-table-column>
        
      </el-table>
      <div class="pagination" style="text-align: end">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allocationStatus: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      tab: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      area: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      clueType: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      userType: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      maxlength:300,
      tableData: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      formInline: {
        user: "",
        region: "",
      },
      increasingRules:{
        name: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        phone:[{ required: true, message: "请输入电话号码", trigger: "blur" ,pattern: /^1[3-9]\d{9}$/}]
      },
      Increasingrules:"",
      textarea: "",
      culeForm:{},
      ruleForm: {
        desc: "",
      },
      value1: "",
      value2: "",
      centerDialogVisible: false,
      currentPage4: 1,
      params: {
        condition: {
          createName: "",
        },
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 10,
      },
      total: 0,
      advanced:false
    };
  },
  methods: {
    toggleAdvanced() {
        this.advanced = !this.advanced
    },
    onSubmit() {
      console.log("submit!");
    },
    handleSizeChange() {},
    changeStatus(){},
    handleCurrentChange() {},
    handleInput() {
      this.charCount = this.culeForm.desc.length;
    },
    getData() {
      this.$http.post("/houseCustomerClue/list", this.params).then((res) => {
        // console.log( res.data.data);
        this.tableData = res.data.data.data;
        this.total = res.data.data.count;
        // console.log(this.tableData);
      });
    },
    sumbitsaveClue(increasingForm){
      this.$refs[increasingForm].validate((valid) => {
        if (valid) {
          this.$http
            .post("/houseCustomer/save", this.culeForm)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("添加成功");
              
               
              } else {
                console.log(res.data);
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
   
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="less" >
.openSea {
  margin-right: 5px;
   .el-form .el-row .el-col-4{
    width: 19.5%;
  }
  .el-form-item__label{
    font-size: 13px;
  }
  .el-form-item--small.el-form-item {
    margin-bottom: 5px;
   }
  .el-date-editor .el-input__icon {
    line-height: 21px !important;
  }
  .openSea-top {
    background-color: #fff;
    padding: 10px 0 0 0 ;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .openSea-bottom {
    background-color: #fff;
    padding: 15px 15px 10px 15px;
    border-radius: 10px;
  }
}
</style>